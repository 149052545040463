import { memo, useContext } from 'react';
import uuid from 'components/utils/uuid';
import { Dropdown, Menu } from 'antd';
import { useParams } from 'react-router-dom';
import { nameElipsis } from 'components/utils/helpers';
import { Tooltip } from 'components/atoms';
import { ManIcon } from 'components/atoms/icon';
import { UserAvatar } from 'components/atoms/avatar';

import * as S from './Styles';
import { Tag as TagBlock } from './Tag';
import { HandlersContext } from './HandlersContext';

export const Row = memo(({ data: rowData, index }: any) => {
  const {
    onRowChecked: onRowSelect,
    handleRowClick: onRowClick,
    checkedList: checkedItems,
  } = useContext<any>(HandlersContext);
  const { contactId } = useParams<Record<string, string | undefined>>();
  const { id, type, symbol, name, profilePicture = '', tags = [], number } = rowData || {};
  const isChecked = checkedItems?.includes(id);
  const isRowActive = id === contactId || isChecked;
  const NUMBER_OF_TAGS_TO_SHOW = 4;

  const tagsToDisplay = tags?.map(
    (tag: Record<string, string>, idx: number) =>
      idx + 1 <= NUMBER_OF_TAGS_TO_SHOW && (
        <TagBlock key={tag?.title} colorCode={tag?.colorCode} title={tag?.title} className='mr-2' />
      ),
  );
  const hiddenTags = tags?.map(
    (tag: Record<string, string>, idx: number) =>
      idx + 1 > NUMBER_OF_TAGS_TO_SHOW && (
        <Menu.Item key={tag?.title}>
          <TagBlock colorCode={tag?.colorCode} title={tag?.title} />
        </Menu.Item>
      ),
  );
  const NAME_CHAR_LIMIT = 20;
  const nameInTooltip = name && name.length > NAME_CHAR_LIMIT ? name : '';

  return (
    <div key={uuid()} className='flex' data-cy={`${index}+contact-row`} id={id}>
      {!type && (
        <S.SingleContactRow
          className={`${
            isRowActive ? 'active' : ''
          } group cursor-pointer w-full  flex items-center  px-3.5 py-2 ml-6 mr-3.5 rounded-10 active:bg-primary-50 hover:bg-extra-lightGray`}
        >
          <div className='mr-3 h-5'>
            <S.CustomCheckbox value={id} onChange={onRowSelect} checked={isChecked} />
          </div>
          <div
            className='flex items-center w-full'
            role='presentation'
            key={id}
            onClick={onRowClick(id)}
          >
            <div className='w-90 flex-shrink-0 flex items-center'>
              <UserAvatar
                shape='square'
                size={32}
                icon={<ManIcon />}
                src={profilePicture}
                className='min-w-32'
              />
              <Tooltip title={nameInTooltip}>
                <h6 className='font-semibold text-13 text-gray-700 leading-5 ml-3'>
                  {nameElipsis(name, NAME_CHAR_LIMIT) || number}
                </h6>
              </Tooltip>
            </div>
            <div className='flex items-center'>
              {tags?.length <= NUMBER_OF_TAGS_TO_SHOW ? (
                tagsToDisplay
              ) : (
                <>
                  {tagsToDisplay}
                  <Dropdown
                    getPopupContainer={trigger => trigger}
                    overlay={<Menu>{hiddenTags}</Menu>}
                    trigger={['click']}
                  >
                    <p
                      role='presentation'
                      onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                      className='more-tags text-gray-600 text-12.5 leading-3.5 py-1 px-2.5 bg-tags-gray50 rounded-5 border border-solid border-gray-50 group-hover:border-gray-200'
                    >
                      +{tags?.length - NUMBER_OF_TAGS_TO_SHOW}
                    </p>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </S.SingleContactRow>
      )}
    </div>
  );
});
