/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo, useRef, useEffect, useContext } from 'react';
import { Skeleton } from 'antd';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { LIMIT } from 'hooks/useContactVars';
import uuid from 'components/utils/uuid';
import { Row } from './Row';
import { HandlersContext } from './HandlersContext';
import * as S from './Styles';

function ContactList() {
  const { contacts, hasNextPage, onLoadMore, loading } = useContext<any>(HandlersContext);

  // ------ Infinite Scroll Implementation ----
  const scollToView = () => {
    try {
      if (contacts.length < LIMIT + 1) return;
      const refItem = contacts?.[contacts.length - 1];
      const element = document.getElementById(refItem?.node?.id);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, entry] = useIntersectionObserver({
    elementRef,
  });
  const targetElement = entry?.target;

  useEffect(() => {
    if (isVisible) {
      if (targetElement instanceof HTMLElement && hasNextPage) {
        targetElement?.click();
        scollToView(); // TODO: ENABLE THIS AFTER FIXING SCROLL ISSUE WHILE SEARCH TEXT
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isVisible, targetElement]);
  // ---------------------------------------------

  return (
    <div>
      {loading && contacts.length < LIMIT ? (
        <div className='w-full'>
          <S.SkeletonContainer>
            {Array.from(Array(20), (item: number, index: number) => (
              <div key={uuid()} className='flex gap-3 px-3.5 py-2 ml-6 mr-3.5 '>
                <Skeleton.Avatar size={32} active />
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
            ))}
          </S.SkeletonContainer>
        </div>
      ) : (
        <>
          {contacts?.map((rowData: any, index: number) => {
            return <Row data={rowData?.node} index={index} key={rowData?.node?.id} />;
          })}
        </>
      )}
      <div ref={elementRef} onClick={onLoadMore} className='h-2' />
    </div>
  );
}

export default memo(ContactList);
