import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { BLOCK_OR_UNBLOCK_NUMBER } from 'graphql/client';
import { Button, ToastMessage, Icon } from 'components/atoms';
import { ActionsModal } from 'components/organisms';
import { NUMBERS, CONTACTS } from 'constants/routes';

export default function BlockUnblockContact({ contact, closeDropdown }: any) {
  const { name, blocked = false, number } = contact || {};
  const history = useHistory();
  const { channelId, contactId } = useParams<Record<string, string | undefined>>();
  const { t } = useTranslation();
  const [visibleBlockModal, setVisibleBlockModal] = useState(false);

  const closeBlockModal = () => setVisibleBlockModal(false);
  const openBlockModal = () => setVisibleBlockModal(true);

  const [blockUnblockContact] = useMutation(BLOCK_OR_UNBLOCK_NUMBER, {
    onCompleted: response => {
      const { data } = response.blockNumber;
      if (data) {
        ToastMessage({
          content: data.blocked ? (
            <Trans i18nKey='toast.contactBlockedSuccess'>
              {{ contactName: name }} blocked successfully.
            </Trans>
          ) : (
            <Trans i18nKey='toast.contactUnBlockedSuccess'>
              {{ contactName: name }} unblocked successfully.
            </Trans>
          ),
          type: 'success',
        });
        closeBlockModal();
        if (channelId && data?.id) {
          history.push(`${NUMBERS}/${channelId}/${data.id}`);
          return;
        }
        if (contactId) {
          history.push(`${CONTACTS}`);
        }
      }
    },
    onError: () =>
      ToastMessage({
        content: t('error.unspecific', 'Something went wrongs.'),
        type: 'danger',
      }),
  });

  const handleBlockContact = async () => {
    await blockUnblockContact({
      variables: {
        number,
        data: {
          blocked: !blocked,
        },
      },
      update(cache, { data: { blockNumber } }) {
        if (blockNumber?.data?.id) {
          cache.modify({
            fields: {
              getContacts: () => {},
              blockedContacts: () => {},
            },
          });
        }
      },
    });
  };
  const onBlockOrUnblock = () => {
    if (blocked) {
      handleBlockContact();
      return;
    }
    openBlockModal();
    closeDropdown();
  };

  return (
    <>
      <Button
        type='link'
        onClick={onBlockOrUnblock}
        className='flex justify-center items-center btn--block'
        icon={<Icon name='minus-circle' />}
        data-cy='contacts-drawer-block-number-button'
      >
        {blocked
          ? t('common.action.unblockNumber', 'Unblock number')
          : t('common.action.blockNumber', 'Block number ')}
      </Button>

      <ActionsModal
        isModalOpen={visibleBlockModal}
        onOk={handleBlockContact}
        onCancel={closeBlockModal}
        onModalCancel={closeBlockModal}
        action='block'
        title={t('blockContact', 'Block Contact')}
        info={t(
          'modal.info.blockContact',
          'The contact will no longer be able to message or contact you.',
        )}
        btnText={t('common.action.block', 'Block')}
        btnIcon={<Icon name='minus-circle-2' />}
        icon={<Icon name='block-user' />}
      />
    </>
  );
}
