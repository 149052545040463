import { Device, Call } from '@twilio/voice-sdk';
import { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

import useAppContext from 'hooks/useAppContext';
import {
  activeAudioInputId,
  activeCampaignCallsDurationVar,
  activeCampaignDurationVar,
  campaignReattemptVar,
  defaultWorkspaceVar,
  isNetworkAvailable,
  transferToVar,
  webRtcPeerConnectionEnabled,
} from 'services/apollo/reactiveVars';
import { AuthContext } from 'contexts/auth/AuthContext';
import { WORKSPACES } from 'graphql/foundation';
import { cache } from 'services/apollo';
import { Query } from 'generated/graphql';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import { ToastMessage } from 'components/atoms';
import { useWorkspaceCredit } from 'components/organisms/workspace/useWorkspaceCredit';
import { useWorkspaceSelector } from 'components/organisms/workspace';
import uuid from 'components/utils/uuid';
import { ERROR_MESSAGES } from 'constants/errors';

import { callEvent, ACTIONS, CALL_WIDGET_STATUS, CAMPAIGN_STATUS } from '../constants';
import { LiveListeningProps, OutgoingProps } from '../types';
import { TWILIO_ERRORS } from '../error';
import useWebRtcTest from '../hooks/useWebRtcTest';
import { getCallParams } from '../utils';

interface IProps {
  dispatch: React.Dispatch<any>;
  deviceInstance: Device | null;
  state: any;
  setDeviceStatus: (val: string) => void;
}

export function useHandlers(props: IProps) {
  const activeMicRef: any = useRef();
  const callStateRef: any = useRef();
  const defaultWorkspaceRef: any = useRef();
  const webRtcPeerConnectionRef: any = useRef();
  const activeTransferToId: any = useRef();
  const unSavedCallWidgetActionRef = useRef<boolean>();
  const reattemptCampaignVisibleRef = useRef<boolean>();
  const internetConnectionRef = useRef<boolean>();

  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const { t } = useTranslation();
  const { dispatch, deviceInstance, state, setDeviceStatus } = props;
  const { unSavedCallWidgetAction } = useAppContext();
  const { sufficientCredit } = useWorkspaceCredit();
  const { checkPeerConnection } = useWebRtcTest();
  const { selectWorkspace } = useWorkspaceSelector();
  const { activeWorkspaceId } = useContext(AuthContext);
  const activeMic = useReactiveVar(activeAudioInputId);
  const defaultWorkspace = useReactiveVar(defaultWorkspaceVar);
  const webRtcPeerConnection = useReactiveVar<any>(webRtcPeerConnectionEnabled);
  const reattemptCampaignVisible = useReactiveVar<any>(campaignReattemptVar);
  const internetConnection = useReactiveVar(isNetworkAvailable);

  activeMicRef.current = activeMic;
  callStateRef.current = state;
  defaultWorkspaceRef.current = defaultWorkspace;
  webRtcPeerConnectionRef.current = webRtcPeerConnection;
  activeTransferToId.current = useReactiveVar(transferToVar);
  unSavedCallWidgetActionRef.current = unSavedCallWidgetAction;
  reattemptCampaignVisibleRef.current = reattemptCampaignVisible;
  internetConnectionRef.current = internetConnection;

  const checkActiveWorkspace = () => {
    const workspaceData: Pick<Query, 'workspaces'> | null = cache?.readQuery({
      query: WORKSPACES,
    });
    if (defaultWorkspaceRef.current && defaultWorkspaceRef.current !== activeWorkspaceId) {
      const activeWorkspace = workspaceData?.workspaces?.data?.find(
        workspace =>
          workspace?.id === defaultWorkspaceRef.current && workspace?.status === 'Active',
      );
      if (activeWorkspace) {
        const { id, memberId } = activeWorkspace;
        ToastMessage({ content: 'Redirecting to your active workspace', type: 'warning' });
        if (id && memberId) selectWorkspace({ workspaceId: id, memberId });
      }
    }
  };

  const handleDeviceIncoming = (call: Call) => {
    const { showPhoneWidget, showDialer, callEnded, salesDialerWidget, campaignStatus } =
      callStateRef.current || {};
    const isCampaignCall = call?.customParameters?.get('isCampaignCall');
    if (
      (salesDialerWidget && !isCampaignCall && campaignStatus !== CAMPAIGN_STATUS.PAUSED) ||
      reattemptCampaignVisibleRef.current
    ) {
      // IGNORE INCOMING CALL IF POWER DIALER WIDGET IS OPEN AND CAMPAIGN IS NOT IN PAUSED STATE
      // IGNORE INCOMING CALL IF POWER DIALER CAMPAIGN REATTEMPT MODAL IS OPEN
      call.reject();
      return;
    }
    if (showPhoneWidget && !showDialer && !isCampaignCall && !callEnded) {
      // IGNORE INCOMING CALL IF PHONE WIDGET IS OPEN FOR NORMAL VOICE CALL
      call.ignore();
      return;
    }
    call.on(callEvent.CANCEL, () => {
      dispatch({
        type: ACTIONS.INCOMING_CALL_CANCEL,
        callWidgetVisible: unSavedCallWidgetActionRef.current,
      });
      if (!isDialerPage) return;
      setTimeout(() => {
        dispatch({
          type: ACTIONS.SHOW_DIALER,
          data: {},
        });
      }, 2000);
    });

    call.on(callEvent.DISCONNECT, async (conn: any) => {
      if (isCampaignCall) {
        if (!internetConnectionRef.current) {
          dispatch({
            type: ACTIONS.CAMPAIGN_DISCONNECT_INTERNET,
            data: conn,
          });
          return;
        }
        const { nextCallQueue, prevCallSkipped, nextQueueAfterSkip } = callStateRef.current || {};
        const { id: nextSkippedCallId } = nextCallQueue || {};
        // Check if the user has skipped the call at list once and has next call in queue
        const nextQueue = conn?.customParameters?.get('nextQueue');
        const isReattemptedCampaign = conn?.customParameters?.get('isReattempt');
        // nextQueue is not updated in conn data received in this disconnect callback fn after skip.
        // So, its latest value is maintained in our local state
        const nextCampaignCallQueueId = prevCallSkipped ? nextQueueAfterSkip : nextQueue;
        // call is marked as last campaign call if nextCampaignCallQueueId is null or none
        const isLastCampaignCall = !nextCampaignCallQueueId || nextCampaignCallQueueId === 'None';
        if (isLastCampaignCall) {
          activeCampaignDurationVar(0); // reset campaign duration on campaign end
          activeCampaignCallsDurationVar(0); // reset campaign dialed calls duration on campaign end
          dispatch({
            type: ACTIONS.CAMPAIGN_COMPLETED,
            data: conn,
          });
          if (isReattemptedCampaign !== 'True') campaignReattemptVar(true);
          return;
        }
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_ENDED,
          data: {},
        });
        return;
      }
      dispatch({
        type: ACTIONS.INCOMING_CALL_DISCONNECT,
        callWidgetVisible: unSavedCallWidgetActionRef.current,
      });
      // check and sync active workspace with other loggedIn device
      checkActiveWorkspace();
      if (!isDialerPage) return;
      setTimeout(() => {
        dispatch({
          type: ACTIONS.SHOW_DIALER,
          data: {},
        });
      }, 2000);
    });

    call.on(callEvent.ACCEPT, (conn: any) => {
      if (conn?.customParameters?.get('isCampaignCall')) return;
      dispatch({
        type: ACTIONS.ANSWER_INCOMING_CALL,
      });
    });

    call.on(callEvent.REJECT, () => {
      dispatch({
        type: ACTIONS.REJECT_INCOMING_CALL,
        updateConversationStatus: true,
      });
      if (!isDialerPage) return;
      setTimeout(() => {
        dispatch({
          type: ACTIONS.SHOW_DIALER,
          data: {},
        });
      }, 2000);
    });

    call.on(callEvent.ERROR, (error: any) => {
      dispatch({
        type: ACTIONS.INCOMING_CALL_ERROR,
        status: 'error',
      });
      datadogLogs.logger.error('Twilio Error : Incoming Call', {
        datadogError: error,
        additionalInfo: {
          callParams: getCallParams(call),
        },
        context: 'call',
      });
      if (TWILIO_ERRORS[error.code] !== undefined) {
        ToastMessage({
          content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
          type: 'danger',
        });
      }
      if (!isDialerPage) return;
      setTimeout(() => {
        dispatch({
          type: ACTIONS.SHOW_DIALER,
          data: {},
        });
      }, 2000);
    });
    call.on('messageReceived', message => {
      const { status, message: callStatusMessage } = message?.content || {};
      if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
        dispatch({
          type: ACTIONS.CALL_TRANSFER_FAILED,
          data: {
            transferTo: activeTransferToId.current,
          },
        });
      }

      if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_CONNECTED) {
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_CONNECTED,
          data: {
            voicemailDropEnabled: true,
          },
        });
      }

      if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED) {
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_DISCONNECTED,
          data: callStatusMessage,
        });
      }
    });
    if (isCampaignCall) {
      call?.accept();
      dispatch({
        type: ACTIONS.CAMPAIGN_CALL_INITATED,
        data: call,
        channelId: call?.customParameters?.get('channel_sid'),
      });
      return;
    }
    dispatch({
      type: ACTIONS.INCOMING_CALL,
      data: call,
      channelId: call?.customParameters?.get('channel_sid'),
    });
  };

  const handleDeviceOutgoing = async (params: OutgoingProps) => {
    if (deviceInstance) {
      if (!internetConnectionRef.current) {
        ToastMessage({
          content: `${t(
            'toast.callFailedNoInternetConnection',
            "Can't make call. Please check your internet connection",
          )}`,
          type: 'danger',
        });
        return;
      }
      checkPeerConnection();
      if (!webRtcPeerConnectionRef.current) {
        ToastMessage({
          content: t(
            'error.networkConnectivityError',
            'Unable to connect. Some third party tools might be affecting your network connectivity',
          ),
          type: 'danger',
        });
        return;
      }
      const isCreditSufficient = await sufficientCredit();
      if (deviceInstance?.state === 'destroyed') {
        ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
        datadogLogs.logger.error('Twilio Error : Unable to make Outbound Call', {
          datadogError: {
            message: 'Device is offline. Unable to make Outbound Call',
            description: 'DeviceInstance state is destroyed',
          },
          additionalInfo: {
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        return;
      }
      if (!isCreditSufficient) return;
      const callParams: any = {
        ...params,
        hubspot_client: true,
        conversation_sid: `CF${uuid()}`,
      };
      dispatch({
        type: ACTIONS.OUTGOING_CALL_ENABLED,
        callParams: params,
      });
      try {
        const call = await deviceInstance.connect({ params: callParams });
        dispatch({
          type: ACTIONS.OUTGOING_CALL_INITIATED,
          data: call,
          channelId: params?.channel_sid,
        });

        call.on('reconnected', () => {
          console.log('reconnected');
        });

        call.on(callEvent.RINGING, (val: boolean) => {
          if (val) {
            dispatch({
              type: ACTIONS.OUTGOING_CALL_RINGING,
            });
          }
        });

        call.on(callEvent.CANCEL, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_DISCONNECT,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
          if (!isDialerPage) return;
          console.log('cancel');
          setTimeout(() => {
            dispatch({
              type: ACTIONS.SHOW_DIALER,
              data: {},
            });
          }, 2000);
        });

        call.on(callEvent.DISCONNECT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_DISCONNECT,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
          // check and sync active workspace with other loggedIn device
          checkActiveWorkspace();
          if (!isDialerPage) return;
          console.log('disconnect');
          setTimeout(() => {
            dispatch({
              type: ACTIONS.SHOW_DIALER,
              data: {},
            });
          }, 2000);
        });

        call.on(callEvent.ACCEPT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_ANSWERED,
          });
        });

        call.on(callEvent.REJECT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_REJECTED,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
          if (!isDialerPage) return;
          setTimeout(() => {
            dispatch({
              type: ACTIONS.SHOW_DIALER,
              data: {},
            });
          }, 2000);
        });

        call.on(callEvent.ERROR, (error: any) => {
          datadogLogs.logger.error('Twilio Error : Outgoing Call', {
            datadogError: error,
            additionalInfo: {
              callParams: getCallParams(call),
            },
            context: 'call',
          });
          if (TWILIO_ERRORS[error.code] !== undefined) {
            ToastMessage({
              content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
              type: 'danger',
            });
          }
        });
        call.on('messageReceived', message => {
          const { status } = message?.content || {};
          if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
            dispatch({
              type: ACTIONS.CALL_TRANSFER_FAILED,
              data: {
                transferTo: activeTransferToId.current,
              },
            });
          }
        });
      } catch (error) {
        datadogLogs.logger.warn('Twilio Error : Unable to make Outbound Call', {
          datadogError: {
            message: 'Device is offline',
            description: 'DeviceInstance not created',
          },
          additionalInfo: {
            error,
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        dispatch({
          type: ACTIONS.OUTGOING_CALL_DISCONNECT,
          callWidgetVisible: unSavedCallWidgetActionRef.current,
        });
      }
      return;
    }
    datadogLogs.logger.warn('Twilio Error : Device is offline', {
      datadogError: {
        message: 'Device is offline',
        description: 'DeviceInstance not created',
      },
      additionalInfo: {
        callParams: {
          parameters: params,
        },
      },
      context: 'twilio device',
    });
    ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
  };

  const handleLiveListening = async (params: LiveListeningProps) => {
    if (deviceInstance) {
      checkPeerConnection();
      if (!webRtcPeerConnectionRef.current) {
        ToastMessage({
          content: t(
            'error.networkConnectivityError',
            'Unable to connect. Some third party tools might be affecting your network connectivity',
          ),
          type: 'danger',
        });
        return;
      }
      const isCreditSufficient = await sufficientCredit();
      if (deviceInstance?.state === 'destroyed') {
        datadogLogs.logger.error('Twilio Error : Unable to listen live calls', {
          datadogError: {
            message: 'Device is offline. Unable to listen live calls',
            description: 'DeviceInstance state is destroyed',
          },
          additionalInfo: {
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
        return;
      }
      if (!isCreditSufficient) return;
      const call = await deviceInstance.connect({ params });

      call.on('reconnected', () => {
        console.log('reconnected');
      });

      call.on(callEvent.DISCONNECT, () => {
        console.log('disconnected');
      });

      call.on(callEvent.ERROR, (error: any) => {
        datadogLogs.logger.error('Twilio Error : live listening', {
          datadogError: error,
          additionalInfo: {
            callParams: getCallParams(call),
          },
          context: 'call',
        });
        if (TWILIO_ERRORS[error.code] !== undefined) {
          ToastMessage({
            content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
            type: 'danger',
          });
        }
      });
      dispatch({
        type: ACTIONS.SET_LIVECALL,
        data: { call, params },
      });
      return;
    }
    datadogLogs.logger.warn('Twilio Error : Device is offline', {
      datadogError: {
        message: 'Device is offline',
        description: 'DeviceInstance not created',
      },
      additionalInfo: {
        callParams: {
          parameters: params,
        },
      },
      context: 'twilio device',
    });
    ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
  };

  const handleDeviceReady = () => {
    console.log('The device is ready to receive incoming calls.');
    setDeviceStatus('online');
    checkPeerConnection(30000);
  };

  const handleDeviceError = (error: any) => {
    datadogLogs.logger.error('Twilio Error : Handle device error', {
      datadogError: error,
      context: 'twilio device',
    });
    if (deviceInstance?.state !== 'registered') {
      setDeviceStatus('offline');
    }
    if (TWILIO_ERRORS[error.code] !== undefined) {
      ToastMessage({
        content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
        type: 'danger',
      });
    }
  };

  const handleDeviceOffline = () => {
    console.log('The device is no longer able to receive calls.');
    setDeviceStatus('offline');
    // REJECT INCOMING CALL IF TWILIO DEVICE IS DESTROYED"
    callStateRef.current?.connection?.reject?.();
  };

  const handleRegisteringDevice = () => {
    console.info('Registering Device');
    setDeviceStatus('loading');
  };

  const handleTokenWillExpire = (callback: any) => {
    callback({});
  };

  return {
    handleDeviceIncoming,
    handleDeviceOutgoing,
    handleDeviceReady,
    handleDeviceError,
    handleDeviceOffline,
    handleRegisteringDevice,
    handleTokenWillExpire,
    handleLiveListening,
  };
}
