import styled from 'styled-components';

import { Button } from 'components/atoms';

export const TopBarStyles = styled.div`
  /* + div {
    height: calc(100% - 65px); // parent container height - topbar height
  } */

  .ant-input-affix-wrapper {
    &.search-contact {
      max-width: 319px;
      height: 40px !important;
      padding: 8px 14px;
      &--sm {
        max-width: 187px !important;
      }
    }
    .ant-input-prefix > svg {
      width: 14px;
      height: 14px;
    }
    .ant-input {
      line-height: 14px;
      &::placeholder {
        color: ${({ theme }) => theme.colors.text.t6};
      }
    }
  }
  .ant-btn {
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    line-height: 14px;
    text-align: center;

    display: flex;
    align-items: center;
  }

  .import-csv-btn {
    color: ${({ theme }) => theme.colors.primary.p4};
    border-color: ${({ theme }) => theme.colors.primary.p4};
    padding: 10px 19px;
    white-space: normal;
    svg {
      min-width: 14px;
    }
  }

  .delete-row-btn {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.danger.d2};
    border: none;
    margin-right: 12px;
    svg {
      min-width: 40px;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.danger.default};
    }
    &--sm {
      margin-right: 10px;
    }
  }
`;

export const AddContactButton = styled(Button)`
  background: ${({ theme }) => `${theme.colors.primary.default}1a`};
  padding: 8px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.text.t9};
  &:hover,
  &:focus {
    background: rgba(57, 1, 121, 0.1);
    transition: transform 0.5s ease;
  }
  > span {
    margin-right: 8px;
    white-space: normal;
  }
  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 4px;
    background: ${({ theme }) => theme.colors.primary.default};
    box-shadow: 0px 5px 15px rgba(57, 1, 121, 0.3);
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
