/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { produce } from 'immer';

// Create the initial value
export const LIMIT = 20;
const initialParams = {
  first: LIMIT,
  search: null,
  filter: null,
  after: null,
};

// Create the Query var and initialize it with the initial value
export const queryParamVars: any = makeVar(initialParams);
export const selectedTags: any = makeVar([]);
export const selectedRows: any = makeVar([]);
export const selectedRowKeys: any = makeVar([]);
export const selectedContact: any = makeVar(null);

// expose the operations done on the state
export function useQVars(qVars: any) {
  const setSearch = (text: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.search = text;
    });
    qVars(updatedQvars);
  };

  const setFilters = (filters: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.filter = filters;
    });
    qVars(updatedQvars);
  };
  const setSelectedRows = (contacts: any) => {
    qVars(contacts);
  };
  const setSelectedTags = (tags: any) => {
    qVars(tags);
  };
  const setSelectedRowKeys = (rowKeys: any) => {
    qVars(rowKeys);
  };

  const setSelectedContact = (id: string | null) => {
    qVars(id);
  };

  return {
    setSearch,
    setFilters,
    setSelectedRows,
    setSelectedContact,
    setSelectedRowKeys,
    setSelectedTags,
  };
}
