import { createContext, useMemo } from 'react';
import { useContactsQuery } from '../useContactsQuery';
import { useHandlers } from './useHandlers';

export const HandlersContext = createContext({});

export const HandlersProvider = ({ children }: any) => {
  const {
    contacts,
    hasContact,
    loading,
    counts,
    called,
    onLoadMore,
    filteredTags,
    hasNextPage,
  } = useContactsQuery();
  const {
    handleRowClick,
    onRowChecked,
    onCheckAllChange,
    checkAll,
    checkedList,
    indeterminate,
    getSearchedQuery,
    showContactDetailDrawer,
    toggleContactDetailDrawer,
    clearSelectedContacts,
  } = useHandlers({ contactList: contacts, counts });

  // Memoize the value object to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      handleRowClick,
      onRowChecked,
      onCheckAllChange,
      indeterminate,
      checkAll,
      checkedList,
      getSearchedQuery,
      showContactDetailDrawer,
      toggleContactDetailDrawer,
      clearSelectedContacts,
      contacts,
      hasContact,
      loading,
      counts,
      called,
      filteredTags,
      hasNextPage,
      onLoadMore,
    }),
    [
      handleRowClick,
      onRowChecked,
      onCheckAllChange,
      indeterminate,
      checkAll,
      checkedList,
      getSearchedQuery,
      showContactDetailDrawer,
      toggleContactDetailDrawer,
      clearSelectedContacts,
      contacts,
      hasContact,
      loading,
      counts,
      called,
      filteredTags,
      hasNextPage,
      onLoadMore,
    ],
  );

  return <HandlersContext.Provider value={contextValue}>{children}</HandlersContext.Provider>;
};
