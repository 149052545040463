import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { GET_CONTACTS } from 'graphql/client';
import { selectedTags, queryParamVars } from 'hooks/useContactVars';
import { produce } from 'immer';

export function useContactsQuery() {
  const qParams = useReactiveVar<any>(queryParamVars);

  const filteredTags = useReactiveVar<any>(selectedTags);

  const [loadPaginatedContacts, { data: query, loading, fetchMore, called }] = useLazyQuery(
    GET_CONTACTS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    loadPaginatedContacts({
      variables: {
        params: qParams,
        tags: filteredTags,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qParams, filteredTags]);

  const { edges: contacts = [], pageInfo } = query?.getContacts?.data || {};
  const endCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;
  const hasContact = contacts.length > 0;
  const counts = contacts?.length ?? 0;

  const onLoadMore = async () => {
    if (fetchMore && endCursor && !loading && hasNextPage) {
      await fetchMore({
        variables: {
          params: {
            ...qParams,
            after: endCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          const contactsData = fetchMoreResult.getContacts.data;
          return produce(prev, (draft: Pick<any, 'getContacts'>) => {
            if (draft?.getContacts?.data) {
              draft.getContacts.data = {
                pageInfo: contactsData.pageInfo,
                edges: draft?.getContacts?.data?.edges?.concat(contactsData.edges),
                // eslint-disable-next-line no-underscore-dangle
                __typename: draft?.getContacts?.data?.__typename,
              };
            }
          });
        },
      });
    }
  };

  return {
    contacts,
    hasContact,
    loading,
    counts,
    called,
    hasNextPage,
    filteredTags,
    onLoadMore,
  };
}
