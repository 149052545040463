import { useTranslation } from 'react-i18next';
import cache from 'services/apollo/cache';

import { useQuery } from '@apollo/client';
import { ToastMessage } from 'components/atoms';
import { GET_WORKSPACE_CREDIT } from 'graphql/foundation';
import useUpdateCache from 'hooks/useUpdateCache';

export function useWorkspaceCredit() {
  const { t } = useTranslation();
  const { refetch } = useQuery(GET_WORKSPACE_CREDIT);
  const { updateCreditCache } = useUpdateCache();

  const getWorkspaceCredit = async () => {
    try {
      const response = await refetch();
      const { data, error } = response?.data?.getWorkspaceCredit || {};
      if (error === null) {
        const creditAmt = data?.currentCredit || 0;
        cache.modify({
          fields: {
            planOverview: () => {},
            workspaces: () => {},
          },
        });
        updateCreditCache(creditAmt);
        return creditAmt;
      }
      return 0;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const sufficientCredit = async (showError = true) => {
    const credit = await getWorkspaceCredit();
    const creditIsSufficient = !(credit <= 0.5);
    if (!creditIsSufficient && showError) {
      ToastMessage({
        content: t('error.inSufficientCredit', 'Insufficient Credit.'),
        type: 'danger',
      });
    }
    return creditIsSufficient;
  };

  return { sufficientCredit };
}
