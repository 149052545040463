import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import * as path from 'constants/routes';
import { ToastMessage } from 'components/atoms';
import { DELETE_CONTACTS } from 'graphql/client';

export function useContactDelete(props?: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { channelId } = useParams<Record<string, string | undefined>>();
  const [visibleDeleteConfirm, setVisibleDeleteConfirm] = useState(false);

  const toggleDeleteConfirm = () => setVisibleDeleteConfirm(prevState => !prevState);

  const [deleteContact, { loading: deleting }] = useMutation(DELETE_CONTACTS, {
    onCompleted: response => {
      const { data } = response.deleteContacts;
      if (data) {
        if (channelId) {
          history.push(`${path.NUMBERS}/${channelId}`);
        } else {
          history.push(`${path.CONTACTS}`);
        }
        ToastMessage({
          content: t('toast.deleteContactSuccess', 'Contact deleted successfully.'),
          type: 'success',
        });
        toggleDeleteConfirm?.();
        props?.clearSelectedContacts();
      }
    },

    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      toggleDeleteConfirm?.();
    },
  });

  const onContactDelete = async (contactIds: any) => {
    await deleteContact({
      variables: {
        data: {
          contacts: contactIds,
        },
      },
      update(cache, { data: { deleteContacts } }) {
        if (deleteContacts?.data?.success) {
          cache.modify({
            fields: {
              tags: () => {},
              getContacts: () => {},
            },
          });
        }
      },
    });
  };

  return {
    onContactDelete,
    visibleDeleteConfirm,
    toggleDeleteConfirm,
    deleting,
  };
}
