import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { Client, Maybe, Tag } from 'generated/graphql';
import { UPDATE_CONTACT } from 'graphql/client';
import { ToastMessage } from 'components/atoms';
import { useAddContactMutation } from 'components/organisms/contact/modals/useAddContactMutation';
import { NUMBERS } from 'constants/routes';
import { cache } from 'services/apollo';
import { customEncodeURIComponent } from 'components/utils/url';

import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import { BannerType } from '../../types';

export function useTag() {
  const { onSaveChangeSuccess } = useCallWidgetDrawerContext();
  const { showBannerLikeToast } = useCallWidgetContext();

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { channelInfo, clientInfo, handleSelectMoreAction } = useActiveCallWidgetContext();
  const { number }: any = clientInfo || {};

  const [updateContactInfo, { loading: loadingAssignTags }] = useMutation(UPDATE_CONTACT, {
    onCompleted: resData => {
      const { error } = resData?.updateContact;
      if (error === null) {
        ToastMessage({
          content: `${t('toast.updateTagsSuccess', 'Tags updated successfully')}`,
          type: 'success',
        });
        showBannerLikeToast?.('Tags added.', BannerType.info);
        handleSelectMoreAction?.();
        cache.modify({
          fields: {
            getContacts: () => {},
            clientDetail: () => {},
            contact: () => {},
          },
        });
        onSaveChangeSuccess();
        return;
      }
      ToastMessage({
        content: error.message,
        type: 'danger',
      });
      showBannerLikeToast?.(error?.message || 'Something went wrong', BannerType.error);
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      showBannerLikeToast?.(t('error.unspecific', 'Something went wrong.'), BannerType.error);
    },
  });

  const onAddContact = (contact: Client) => {
    onSaveChangeSuccess();
    handleSelectMoreAction?.();
    const { pathname } = location;
    if (
      pathname.includes(
        `${channelInfo?.id}/new/?phone=${customEncodeURIComponent(clientInfo?.number)}`,
      )
    ) {
      history.push(`${NUMBERS}/${channelInfo?.id}/${contact?.id}`);
    }
  };

  const { addContact, loading } = useAddContactMutation({ onAddContact });

  const assignTags = (clientTags: Maybe<Tag>[]) => {
    const tagIds = clientTags.map((tag: any) => tag.id);
    if (!clientInfo?.id) {
      const payload = {
        name: number,
        number,
        visibility: true, // Save contact as public by default
        tags: tagIds,
      };
      addContact(payload);
      return;
    }
    if (clientInfo?.id) {
      updateContactInfo({
        variables: {
          id: clientInfo.id,
          data: {
            tags: tagIds,
          },
        },
      });
    }
  };
  return {
    assignTags,
    loadingAssignTags: loadingAssignTags || loading,
  };
}
