import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddLine } from 'react-icons/ri';

import { selectedRowKeys, selectedRows, useQVars } from 'hooks/useContactVars';
import { Icon } from 'components/atoms';
import * as S from './Styles';
import { ImportContactModal, AddContactModal } from '../modals';

interface ITopBar {
  numberOfContacts?: number;
}

export default function TopBar({ numberOfContacts = 0 }: ITopBar) {
  const { t } = useTranslation();
  const [visibleImportContactModal, setVisibleImportContactModal] = useState(false);
  const { setSelectedRows } = useQVars(selectedRows);
  const { setSelectedRowKeys } = useQVars(selectedRowKeys);
  const toggleImportContactModal = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setVisibleImportContactModal(prevState => !prevState);
  };

  const [visibleAddContactModal, setVisibleAddContactModal] = useState(false);

  const toggleAddContactModal = () => {
    setVisibleAddContactModal(prevState => !prevState);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  useEffect(() => {
    return () => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const hideTotalContacts = process.env.REACT_APP_HIDE_TOTAL_COUNT_CONTACTS_PAGE === 'true';

  return (
    <S.TopBarStyles data-cy='contact-page' className='sticky bg-white z-10 top-0'>
      <div className='py-3 px-4 shadow-bottom'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center pl-2 min-h-40'>
            <h6
              className='text-lg font-bold text-gray-600 leading-6.25 mr-2.5'
              data-cy='contacts-header-title'
            >
              Contacts
            </h6>
            {!hideTotalContacts && (
              <h6 className='tracking-wide text-13 leading-3.5 text-gray font-semibold py-0.75 px-1.25 border-gray-300 border rounded-lg'>
                {numberOfContacts}
              </h6>
            )}
          </div>
          {numberOfContacts > 0 && (
            <>
              <div className='flex items-center'>
                <button
                  data-cy='contacts-add-button'
                  type='button'
                  className='flex items-center bg-primary-50 rounded-lg py-2.25 px-6 gap-2 font-semibold text-primary'
                  onClick={toggleAddContactModal}
                >
                  <RiAddLine size={20} />
                  <h6 className='text-primary font-semibold'>
                    {t('addNewContact', 'Add New Contact')}
                  </h6>
                </button>
                <button
                  data-cy='contact-add-cloud'
                  type='button'
                  className='ml-3 bg-primary-50 rounded-lg p-2.75'
                  onClick={toggleImportContactModal}
                >
                  <Icon name='cloud-plus' />
                </button>
              </div>
              <AddContactModal
                data-cy='contacts-add-modal'
                isModalOpen={visibleAddContactModal}
                onCancel={toggleAddContactModal}
                onModalCancel={toggleAddContactModal}
                showAdditionalFields
                width={448}
              />
            </>
          )}
        </div>
        <ImportContactModal
          isModalOpen={visibleImportContactModal}
          onCancel={toggleImportContactModal}
          onModalCancel={toggleImportContactModal}
          width={448}
        />
      </div>
    </S.TopBarStyles>
  );
}
