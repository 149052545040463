import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useContext, memo, useEffect, useRef } from 'react';

import { Client } from 'generated/graphql';
import { UPDATE_CONTACT } from 'graphql/client';
import { ToastMessage } from 'components/atoms';
import { NUMBERS, CONTACTS } from 'constants/routes';
import UploadPic from 'components/molecules/upload-pic';
import { ContactContext } from 'components/organisms/contact/ContactContext';

import { useAddContactMutation } from '../../modals/useAddContactMutation';

function ClientProfilePic() {
  const { t } = useTranslation();
  const { contactData } = useContext<any>(ContactContext);
  const childRef: any = useRef(null);
  const { id, name = '', profilePicture = '', number = '' } = contactData || {};
  const { channelId } = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const [imageFile, setImageFile] = useState(null);

  const [updateContactInfo] = useMutation(UPDATE_CONTACT, {
    onCompleted: resData => {
      const { error } = resData?.updateContact;
      if (error === null) {
        ToastMessage({
          content: `${t('toast.contactProfileUpdated', 'Profile picture updated successfully.')}`,
          type: 'success',
        });
        setImageFile(null);
        return;
      }
      if (resData?.updateContact?.error?.message) {
        ToastMessage({
          content: t(`${resData?.updateContact?.error?.message}`),
          type: 'danger',
        });
        if (childRef.current) {
          childRef?.current?.handleRemovePic();
        }

        return;
      }
      ToastMessage({
        content: error.message,
        type: 'danger',
      });
      if (childRef.current) {
        childRef?.current?.handleRemovePic();
      }
    },
    onError: () =>
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      }),
  });

  const onAddContact = (contact: Client) => {
    if (channelId) {
      history.push(`${NUMBERS}/${channelId}/${contact?.id}`);
      return;
    }
    history.push(`${CONTACTS}/${contact?.id}`);
  };

  const { addContact } = useAddContactMutation({ onAddContact });

  const changeUserProfilePicture = async () => {
    if (!id) {
      const payload = {
        name,
        number,
        visibility: true, // Save contact as public by default
        photoUpload: imageFile,
      };
      addContact(payload);
      return;
    }
    updateContactInfo({
      variables: {
        id,
        data: {
          photoUpload: imageFile,
        },
      },
      update(cache, { data }) {
        const cacheId = data?.updateContact?.data && cache.identify(data.updateContact.data);
        if (cacheId) {
          cache.modify({
            fields: {
              contact: () => {},
              recentConversationHistory: () => {},
              contactPinnedConversationOpenClosed: () => {},
            },
          });
        }
      },
    });
  };
  const onImgUpload = (img: any) => {
    setImageFile(img);
  };
  useEffect(() => {
    if (imageFile) {
      changeUserProfilePicture();
    }
    return () => setImageFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageFile]);

  return (
    <UploadPic
      data-cy='upload-pic-wrapper'
      ref={childRef}
      remove={false}
      position='center'
      data={profilePicture}
      onImgUpload={onImgUpload}
      editText='Change'
      type='user'
    />
  );
}

export default memo(ClientProfilePic);
