import styled from 'styled-components';

export const CallActionWrapper = styled.button`
  width: 100%;
  &.end-call {
    width: auto !important;
  }
  > svg {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    padding: 16px;
    border-radius: 24px;
  }
  > span {
    margin-top: 8px;
    display: block;
    color: var(--Text-500-Component, #333036);
    font-size: 13px;
    line-height: 18px;
  }

  :active,
  :focus {
    > svg {
      background: var(--Background-400, #edebee);
    }
  }

  &.primary {
    > svg {
      background: var(--Background-300, #f5f4f6);
      color: #4d4950;
    }
  }

  &.danger {
    > svg {
      background: var(--Error-100, #ffefef);
      color: var(--Error-300-Default, #f33);
    }
  }
  &.info {
    > svg {
      background: var(--Link-100, #ebf5ff);
      color: #007aff;
    }
  }

  /* variant */
  &.secondary {
    > svg {
      padding: 13px;
      background: var(--Error-300-Default, #f33);
      color: white;
    }
  }

  &:disabled {
    > svg,
    > span {
      color: var(--Text-200-Disabled, #99949e);
    }
    > svg {
      background: var(--Background-300, #f5f4f6);
    }

    + div > div > .ant-select-disabled {
      .ant-select-selector {
        background: var(--Background-400, #edebee) !important;
        + .ant-select-arrow > svg {
          color: #99949e !important;
        }
      }
    }
  }
`;
