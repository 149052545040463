import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import cache from 'services/apollo/cache';

import { ToastMessage } from 'components/atoms';
import { errorKeys } from 'constants/errors';
import { UPLOAD_CSV_CONTACT } from 'graphql/client';

export function useContactsMutation(props?: any) {
  const { t } = useTranslation();

  const [uploadCsv, { loading: isImporting }] = useMutation(UPLOAD_CSV_CONTACT, {
    onCompleted(resData) {
      const { error, data } = resData?.uploadContact || {};
      if (error === null) {
        ToastMessage({
          content: `${data?.savedRecords} ${
            data?.savedRecords > 1
              ? t('contactsImported', 'contacts imported.')
              : t('contactImported', 'contact imported.')
          }`,
          icon: (
            <span>
              <RiCheckboxCircleFill />
            </span>
          ),
          type: 'success',
        });
        cache.modify({
          fields: {
            getContacts: () => {},
          },
        });
        props?.closeModal?.();
        return;
      }
      if (error?.errorKey === 'limit_exceed') {
        ToastMessage({
          content: error?.message || t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
        return;
      }
      // TODO: WE MIGHT NOT NEED TO MAINTAIN THE ERROR KEY MESSAGES IN FRONTEND IF ALL THE ERROR MESSAGES ARE SENT IN RESPONSE
      const err = errorKeys.find(item => item.errorKey === error?.errorKey);
      ToastMessage({
        content: err?.message || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
    onError() {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const uploadCsvContact = async (payload: any) => {
    try {
      uploadCsv({
        variables: {
          data: payload,
        },
      });
    } catch (err) {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    }
  };

  return {
    uploadCsvContact,
    isImporting,
  };
}
